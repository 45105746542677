import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

import VideoPlayer from '@components/scrollytelling/latin-america-migration/multimedia/video-element'

const InfographicContent = ({ chapter, media }) => {
	const CHAPTERS = [
		{ chapter: 1, type: 'img-contain', image: 'darien-1', audio: '', video: '' }, // 1
		{ chapter: 2, type: 'img-contain', image: 'darien-2', audio: '', video: '' }, // 2
		{ chapter: 3, type: 'img-contain', image: 'darien-3', audio: '', video: '' }, // 3
		{ chapter: 4, type: 'img-contain', image: 'darien-4', audio: '', video: '' }, // 4
		{ chapter: 5, type: 'img-contain', image: 'darien-5', audio: '', video: '' }, // 5
		{ chapter: 6, type: 'img-contain', image: 'darien-6', audio: '', video: '' }, // 6
		{ chapter: 7, type: 'img-contain', image: 'darien-7', audio: '', video: '' }, // 7
		{ chapter: 8, type: 'img-contain', image: 'darien-8', audio: '', video: '' }, // 8
		{ chapter: 9, type: 'img-contain', image: 'mexico-1', audio: '', video: '' }, // 9
		{ chapter: 10, type: 'img-contain', image: 'mexico-2', audio: '', video: '' }, // 10
		{ chapter: 11, type: 'img-contain', image: 'mexico-3', audio: '', video: '' }, // 11
		{ chapter: 12, type: 'img-contain', image: 'mexico-4', audio: '', video: '' }, // 12
		{ chapter: 13, type: 'img-contain', image: 'mexico-5', audio: '', video: '' }, // 13
		{ chapter: 14, type: 'img-contain', image: 'usborder-1a', audio: '', video: '' }, // 14
		{ chapter: 15, type: 'img-contain', image: 'usborder-2', audio: '', video: '' }, // 15
		{ chapter: 16, type: 'img-contain', image: 'usborder-3', audio: '', video: '' }, // 16
		{ chapter: 17, type: 'img-contain', image: 'usborder-4', audio: '', video: '' }, // 17
		{ chapter: 18, type: 'img-and-audio', image: 'usborder-5', audio: '', video: '' }, // 18
		{ chapter: 19, type: 'img-contain', image: 'usborder-6', audio: '', video: '' }, // 19
		{ chapter: 20, type: 'img-contain', image: 'usborder-7', audio: '', video: '' }, // 20
		// { chapter: 20, type: 'img-cover', image: 'usborder-7', audio: '', video: '' }, // 20
	]

	const currentChapterContent = CHAPTERS.filter((el) => el.chapter === chapter)[0]

	const getChapterImage = () => {
		return media.filter((el) => el.node.name === currentChapterContent.image)[0].node.childImageSharp.gatsbyImageData
	}

	return (
		// <div className={'absolute top-0 left-0 w-full h-full migrants-gradient'}>
		<div className={'absolute top-0 left-0 w-full h-full'}>
			{currentChapterContent?.type === 'img-cover' && (
				<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
					{/* Append image / map */}
					<GatsbyImage image={getChapterImage()} objectFit={'cover'} className={'w-full h-full absolute top-0 left-0'} alt='No alt text found.' />
				</motion.div>
			)}
			{currentChapterContent?.type === 'img-contain' && (
				<>
					{/* Append image / map */}
					<GatsbyImage image={getChapterImage()} objectFit={'contain'} className={'w-full h-full absolute top-0 left-0'} alt='No alt text found.' />
				</>
			)}
			{currentChapterContent?.type === 'img-and-audio' && (
				<>
					{/* Append image / map */}
					<GatsbyImage
						image={getChapterImage()}
						// objectFit={'cover'}
						objectFit={'contain'}
						className={'w-full h-full absolute top-0 left-0'}
						alt='No alt text found.'
					/>
					{/* Overlay with audio */}
					{/* <AudioPlayer fileName={'dec14.m4a'} /> */}
				</>
			)}
			{currentChapterContent?.type === 'video-contain' && (
				<>
					{/* Append video player in normal size */}
					<VideoPlayer layout={'contain'} fileName={currentChapterContent.video === '' ? 'Darien.mp4' : `${currentChapterContent.video}.mp4`} autoPlay={true} />
				</>
			)}
			{/* <p className={'text-gray-50'}>Caption / Credit etc.</p> */}
		</div>
	)
}

export default InfographicContent
