import React from 'react'
import { isMobile } from 'react-device-detect'
import { GatsbyImage } from 'gatsby-plugin-image'

const VideoHeader = (props) => {
	// const [isMobileSSR, setIsMobileSSR] = React.useState(false)

	// React.useEffect(() => {
	// 	setIsMobileSSR(isMobile)
	// }, [])

	// if (isMobileSSR) {
		const Images = props.images

		const getImageByName = (name) => {
			return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
		}

		return (
			<>
			<div className={'block sm:hidden'}>
				<GatsbyImage
					image={getImageByName('teaser-1')}
					layout={'fill'}
					// className={'absolute top-0 left-0 w-full h-full'}
					alt={''}
					imgStyle={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
				/>
				<div className={'pb-10 absolute top-0 left-0 w-full h-full flex items-center justify-end flex-col z-1'}>
					<div className={'px-4'}>{props.children}</div>
				</div>
			</div>
			<div className={'z-50 hidden sm:block'}>
				<video
					className='absolute object-cover min-w-full min-h-screen overflow-hidden opacity-100 h-screen-desktop max-w-none'
					autoPlay
					muted
					loop
					placeholder={'/images/missing-migrants/video-placeholder.webp'}
					style={{
						position: 'absolute',
						width: '100% !important',
						height: 'auto',
						transform: 'translate(-50%, -50%)',
						top: '50%',
						left: '50%',
						objectFit: 'cover !important',
					}}
				>
					<source src={'https://media.thenewhumanitarian.org/lat-am-migration-header.mp4'} type={'video/mp4'} />
					<source src={'https://interactive.thenewhumanitarian.org/video/lat-am-migration-header.mp4'} type={'video/mp4'} />
					<source src={require('@images/stories/2022/latin-america-migration/Darien.mp4').default} type={'video/mp4'} />
				</video>
				<div className={'absolute top-0 left-0 flex justify-end items-center content-center w-full h-full flex-col'}>
					<div className={'max-w-none mb-16'}>{props.children}</div>
				</div>
			</div>
			</>
		)
}

export default VideoHeader
