import React, { useEffect, useRef, useState } from 'react'
import { animate } from 'framer-motion'
import * as d3scale from 'd3-scale'
import * as d3array from 'd3-array'
import { Tooltip } from 'react-tooltip'
// import Tooltip from 'react-simple-tooltip'
// import { isMobile } from 'react-device-detect'

import 'react-tooltip/dist/react-tooltip.css'

const numberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function HeatMap({ text }) {
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October']

	const data = [
		{
			year: 2020,
			series: [52254, 54884, 51869, 29743, 39877, 50086, 53672, 65707, 73861, 61159, 57724, 56186],
		},
		{
			year: 2021,
			series: [95276, 115559, 192025, 196190, 198459, 207823, 233919, 231243, 213622, 90585, 89072, 92746],
		},
		{
			year: 2022,
			series: [186755, 190478, 249198, , , , , , , , , ,],
		},
	]

	const allNumbers = data[0].series.concat(data[1].series, data[2].series)
	// console.log(allNumbers)

	const extent = d3array.extent(allNumbers)
	// console.log(extent)

	const linearColor = d3scale.scaleLinear().domain([extent[0], extent[1]]).range(['#fef2f2', '#ef4444'])

	return (
		<div>
			{text && <p className={'divide-y-4'}>{text}</p>}
			<table>
				<thead>
					<tr>
						<td className={'w-20'} />
						<td>J</td>
						<td>F</td>
						<td>M</td>
						<td>A</td>
						<td>M</td>
						<td>J</td>
						<td>J</td>
						<td>A</td>
						<td>S</td>
						<td>O</td>
						<td>N</td>
						<td>D</td>
					</tr>
				</thead>
				<tbody>
					{data.map((el, i) => (
						<tr key={`tr-of-year--${el.year}}-${i}`}>
							<td className={'w-20'}>{el.year}</td>
							{el.series.map((monthData, i) => {
								return (
									<td
										className={'relative'}
										style={{
											background: linearColor(monthData),
										}}
										key={`td-of-year--${el.year}-${i}}`}
									>
										<Tooltip id="my-tooltip" />
										<Tooltip content={numberWithCommas(monthData)} style={{ position: 'unset' }}>
											<div data-tooltip={numberWithCommas(monthData)} className={'absolute top-0 left-0 w-full h-full'} />
										</Tooltip>
									</td>
								)
							})}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

function Counter({ from, to, duration, delay, text }) {
	const nodeRef = useRef()

	const [showDescription, setShowDescription] = useState(false)

	useEffect(() => {
		const node = nodeRef.current

		const controls = animate(from, to, {
			duration: duration || 10,
			// ease: 'easeOut',
			damping: 5,
			mass: 0.75,
			stiffness: 100,
			delay: delay || 0,
			onUpdate(value) {
				node.textContent = numberWithCommas(value.toFixed(0))
			},
			onComplete(value) {
				setShowDescription(true)
			},
			onPlay(value) {
				setShowDescription(false)
			},
		})

		return () => controls.stop()
	}, [from, to])

	return (
		<div className={'flex items-center justify-center flex-col py-4'}>
			{text && <p className={'divide-y-4'}>{text}</p>}
			<div
				className={`text-center pt-6 m-0 text-8xl sm:text-9xl flex items-center justify-center font-bold transition-colors duration-1000 ${showDescription ? 'text-white' : 'text-zinc-300'
					}`}
				ref={nodeRef}
			/>
		</div>
	)
}

const CounterComponent = ({ started }) => {
	// const [isMobileSSR, setIsMobileSSR] = React.useState(false)

	// React.useEffect(() => {
	// 	setIsMobileSSR(isMobile)
	// }, [])

	return (
		<div className={'sm:pt-8 sm:pb-10 bg-green-800 my-14'}>
			<div className={'bg-green-900 p-8 relative sm:-mx-48 grid grid-cols-1 sm:grid-cols-2 shadow-lg'}>
				<div className={'text-center flex align-center justify-center px-8'}>
					<Counter
						from={0}
						to={started ? 133726 : 0}
						duration={2.75}
						delay={0}
						text={'Migrants known to have survived passage through the Darién Gap, 2021'}
					/>
				</div>
				<div className={'mt-8 sm:mt-0 text-center'}>
					<HeatMap text={'Monthly apprehensions at US-Mexico border'} />
					{/* <p className={'text-zinc-400 pt-4 sm:hidden'}>(Tap the rectangles to see the numbers)</p> */}
					{/* <p className={'text-zinc-400 pt-4 hidden sm:block'}>(Hover over the rectangles to see the numbers)</p> */}
				</div>
			</div>
		</div>
	)
}

export default CounterComponent
