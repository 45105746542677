import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { motion } from 'framer-motion'
import { InView } from 'react-intersection-observer'

const VideoElement = ({ autoPlay, isPlaying, muted, fileName }) => {
	const [isMobileSSR, setIsMobileSSR] = React.useState(false)

	React.useEffect(() => {
		setIsMobileSSR(isMobile)
	}, [])

	const vidRef = useRef(null)

	return (
		<InView
			as='div'
			triggerOnce={true}
			threshold={1}
			rootMargin={isMobileSSR ? '0px' : '100px'}
		>
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: 0.5 }}>
				<video controls autoPlay={false} muted={muted} className={'w-full h-full absolute top-0 left-0'} ref={vidRef}>
					<source src={require(`@images/stories/2022/latin-america-migration/${fileName}#t=0.1`).default} type={'video/mp4'} />
				</video>
			</motion.div>
		</InView>
	)
}

export default VideoElement
