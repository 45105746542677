import React, { useRef, useState, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

const ChapterVideo = (props) => {
	const vidRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)

	useEffect(() => {
		if (isPlaying) {
			vidRef.current.play()
		} else {
			vidRef.current.pause()
		}
	}, [isPlaying])

	const playVideo = () => {
		vidRef.current.play()
		setIsPlaying(true)
	}

	const PlayIconSquare = () => {
		return (
			<svg xmlns='http://www.w3.org/2000/svg' width='100%' fill='none' viewBox='0 0 394 394' className={'w-full inline fill-current shadow-2xl'}>
				<g clipPath='url(#clip0_95_12)'>
					{/* <path fill='#fff' d='M0 0H394V394H0z'></path> */}
					<path className={'fill-current'} d='M0 1H394V396H0z'></path>
					<path fill='#1a241f' d='M80.385 91.424H312.984V294.06100000000004H80.385z'></path>
					<path
						className={'fill-current'}
						d='M197.375.75C88.925.75.75 88.925.75 197.375S88.925 394 197.375 394 394 305.825 394 197.375 305.825.75 197.375.75zm63.338 206.551l-92.732 61.687c-7.859 5.385-18.628-.42-18.628-9.928V135.699c0-9.524 10.769-15.312 18.628-9.928l92.716 61.67c7.049 4.544 7.049 15.313.016 19.857v.003z'
					></path>
				</g>
				<defs>
					<clipPath id='clip0_95_12'>
						<path fill='#1a241f' d='M0 0H394V394H0z'></path>
					</clipPath>
				</defs>
			</svg>
		)
	}

	return (
		<InView
			as='div'
			onChange={(inView) => {
				if (inView) {
				} else {
					vidRef.current.pause()
				}
			}}
			className={'w-full'}
		>
			<div className={'relative'}>
				<video
					ref={vidRef}
					controls
					playsInline
					// poster={require(`@images/stories/2022/latin-america-migration/${props.fileName}.jpg`).default}
					width={'100%'}
					height={'auto'}
					style={{
						width: '100% !important',
					}}
				>
					<source src={require(`@images/stories/2022/latin-america-migration/${props.fileName}.mp4`).default} type={'video/mp4'} />
				</video>
				{!isPlaying && (
					<div className={'hidden sm:flex justify-center items-center w-full h-full absolute top-0 left-0 cursor-pointer'} onClick={() => playVideo()}>
						<motion.span
							className={'text-jungle z-50 w-20 h-20 sm:w-48 sm:h-48 overflow-hidden'}
							initial={{
								opacity: 0.5,
							}}
							animate={{
								opacity: 1,
								scale: [1, 1.2, 1.2, 1, 1],
								rotate: [0, 360],
								borderRadius: ['100%', '20%'],
							}}
							whileHover={{ scale: 1.1 }}
							whileTap={{ scale: 0.9 }}
							transition={{ type: 'spring', stiffness: 100 }}
						>
							{/* <PlayIcon /> */}
							<PlayIconSquare />
						</motion.span>
					</div>
				)}
			</div>
		</InView>
	)
}

export default ChapterVideo
