import React, { useState, useCallback } from 'react'

/* Libs and helpers */
import { InView } from 'react-intersection-observer'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import { AnimatePresence } from 'framer-motion'
import { scroller } from 'react-scroll'
import debounce from 'lodash.debounce'
import { GatsbyImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'

/* Layout components */
import Layout from '@components/common/layout'
import Sticky from 'react-stickynode'
import InfographicOverlay from '@components/scrollytelling/latin-america-migration/overlays/infographic-overlay'
import CounterComponent from '@components/scrollytelling/latin-america-migration/counter'
import ChapterVideo from '@components/scrollytelling/latin-america-migration/multimedia/chapter-video'
import InlineVideoSection from '@components/scrollytelling/latin-america-migration/multimedia/inline-video'
import VideoHeader from '@components/scrollytelling/latin-america-migration/multimedia/video-header'

import './styles.css'

const scrollToElement = (element, offset) => {
	if (typeof document === 'undefined') {
		return
	}

	scroller.scrollTo(element, {
		duration: 150,
		delay: 0,
		smooth: 'ease',
		offset: offset || isMobile ? -140 : -200,
	})
}

const scrollToChapter = (element, offset) => {
	if (typeof document === 'undefined') {
		return
	}

	scroller.scrollTo(element, {
		duration: 150,
		delay: 0,
		smooth: 'ease',
		offset: isMobile ? -window.innerHeight / 3 : -window.innerHeight / 2.5 + 80,
	})
}

const Link = (props) => {
	return (
		<a
			target={props.target || '_blank'}
			href={props.href || '#'}
			className={'font-bold transition-colors duration-100 underline hover:text-zinc-200'}
		>
			{props.children}
		</a>
	)
}

const Divider = ({ margin }) => {
	return <hr className={`border-white border-2 mx-8 sm:mx-72 ${margin ? `mt-${margin[0]} mb-${margin[1]}` : 'mb-24 mt-12'} border-dashed`} />
}

const ChapterTitle = ({ text }) => {
	return <h2 className={'text-4xl mb-6 sm:mb-0 sm:text-6xl text-center text-white pt-12'}>{text}</h2>
}

const LatinAmericaMigrantsStory = (props) => {
	const [chapter, setChapter] = useState(1)
	const [counterStarted, setCounterStarted] = useState(false)

	const Images = props.data.allFile.edges

	const getImageByName = (name) => {
		return Images.filter((el) => el.node.name === name)[0].node.childImageSharp.gatsbyImageData
	}

	const changeChapter = (newChapter) => {
		setChapter(newChapter)
	}

	const debouncedchangeChapter = useCallback(debounce(changeChapter, 800), [])
	const debouncedSetCounterStarted = useCallback(debounce(setCounterStarted, 500), [])

	const ArticleTags = () => {
		return (
			<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-8'}>
				<a
					href='https://www.thenewhumanitarian.org/migration'
					title='View more reports on Migration'
					className='p-1 mr-4 font-bold text-gray-100'
					data-report-theme='Migration'
					style={{
						backgroundColor: '#35b58b',
					}}
				>
					Migration
				</a>

				<a
					href='https://www.thenewhumanitarian.org/special-report'
					title='View more Special Reports'
					className='mr-4 font-bold text-gray-50 hover:bg-transparent'
				>
					Special Report
				</a>

				<span className={'text-gray-400'}>10 May 2022</span>
			</div>
		)
	}

	const DateLine = () => {
		return (
			<p
				style={{
					fontSize: '2rem',
					lineHeight: 1.2,
					fontFamily: 'roboto,sans-serif',
					fontWeight: 600,
					color: 'rgba(255,255,255,0.5)',
					letterSpacing: '-.19px',
					textTransform: 'none',
					display: 'block',
					marginBottom: '1rem',
					marginTop: '2rem',
				}}
			>
				DARIÉN GAP, Colombia
			</p>
		)
	}

	const AuthorInfo = () => {
		return (
			<div className={'px-8 sm:px-0 inverted max-w-6xl flex flex-row justify-start items-center w-full mb-16'}>
				<div
					className={'w-32 h-32 flex-shrink-0 rounded-full'}
					style={{
						background: 'url(https://assets.irinnews.org/s3fs-public/styles/author/public/5ajbnfbf_400x400.jpg?aWilRtqsNJG8y1YEaFS3eM0A4BGkTbmk&itok=FhT9FqyJ)',
						backgroundSize: 'cover',
					}}
				/>
				<div className={'text-white ml-6'}>
					<Link href='https://www.thenewhumanitarian.org/authors/joshua-collins' target='_blank'>
						<h3>Joshua Collins</h3>
					</Link>
					<p>Freelance journalist, based in Bogotá, focused on migration and violence</p>
				</div>
			</div>
		)
	}

	const ChapterReset = ({ chapter }) => {
		return (
			<InView
				className={'h-0'}
				as='div'
				threshold={0.4}
				onChange={(inView) => {
					if (inView) {
						debouncedchangeChapter(chapter || -1)
					}
				}}
			/>
		)
	}

	const ChapterMarker = ({ id }) => {
		return <div className={'absolute top-0 left-0'} id={id} />
	}

	const ResponsiveArrow = () => {
		const [isMobileSSR, setIsMobileSSR] = React.useState(false)

		React.useEffect(() => {
			setIsMobileSSR(isMobile)
		}, [])

		return isMobileSSR ? '↑' : '	←'
	}

	const ScrollDownIcon = (props) => {
		if (props.scrollTo) {
			return (
				<div className={'cursor-pointer w-full flex items-center justify-center h-0 sm:h-72'} onClick={() => scrollToChapter(props.scrollTo)}>
					<div className={'w-16 h-16 animate-ping opacity-30'}>
						<svg xmlns='http://www.w3.org/2000/svg' width='100%' viewBox='0 0 100 100' className={'inline fill-current w-full h-full'}>
							<path fill='none' stroke='#fff' strokeWidth='14' d='M78.285 35.996L50 64.277l-28.285-28.28'></path>
						</svg>
					</div>
				</div>
			)
		}

		return (
			<div className={'w-full flex items-center justify-center h-72'} onClick={() => console.log(props)}>
				<div className={'w-16 h-16 animate-ping opacity-30'}>
					<svg xmlns='http://www.w3.org/2000/svg' width='100%' viewBox='0 0 100 100' className={'inline fill-current w-full h-full'}>
						<path fill='none' stroke='#fff' strokeWidth='14' d='M78.285 35.996L50 64.277l-28.285-28.28'></path>
					</svg>
				</div>
			</div>
		)
	}

	const RoundNumber = (props) => {
		return (
			<div
				// className={'w-16 h-16 rounded-full bg-gray-50 text-center flex items-center justify-center sm:absolute sm:top-4 sm:left-4 drop-shadow-2xl mb-8 sm:mb-0 z-40'}
				className={
					'w-16 h-16 rounded-full bg-gray-50 text-center hidden items-center justify-center sm:absolute sm:top-4 sm:left-4 drop-shadow-2xl mb-8 sm:mb-0 z-40'
				}
			>
				<h2 className={'text-5xl m-0 p-0 text-green-900 pb-2'}>{props.number}</h2>
			</div>
		)
	}

	const MapGridWrapper = (props) => {
		return (
			<div className={`w-full relative`}>
				<div className={'relative w-full'}>
					<div className={'w-full px-8 text-left inverted flow relative'}>{props.children}</div>
				</div>
			</div>
		)
	}

	const MapGridSection = (props) => {
		const bgColorClass = props.sticky || props.transparent ? 'bg-transparent' : 'latin-america-gradient'

		const [isMobileSSR, setIsMobileSSR] = React.useState(false)

		React.useEffect(() => {
			setIsMobileSSR(isMobile)
		}, [])

		return (
			<InView
				as='div'
				triggerOnce={true}
				threshold={isMobileSSR ? 0.75 : 1}
				rootMargin={isMobileSSR ? '0px' : '100px'}
				onChange={(inView) => {
					if (inView) {
						if (props.chapter !== chapter) {
							debouncedchangeChapter(props.chapter)
						}
					}
				}}
			>
				<motion.div
					className={`relative rounded min-h-screen/3-5 sm:min-h-screen/2 py-16 sm:py-0 sm:p-6 flex flex-col items-center sm:items-start justify-center transition-opacity duration-100
					${props.chapter === 1 ? 'sm:mt-1/2' : ''}
					${props.chapter === 8 ? 'sm:mb-1/2' : ''}
					${props.chapter === 20 ? 'sm:mb-1/2' : ''}
          ${isMobileSSR ? 'bg-transparent' : bgColorClass}`}
					initial={{
						opacity: 0.4,
					}}
					animate={{
						opacity: props.chapter === chapter || props.sticky ? 1 : 0.4,
					}}
					transition={{
						duration: 0.2,
					}}
				>
					{props.children}
				</motion.div>
			</InView>
		)
	}

	return (
		<Layout
			inverted
			bgColor={'bg-jungle shadow-2xl'}
			shareProps={{
				title: 'The New Humanitarian | ‘The invisibles’: A Cuban asylum seeker’s dangerous odyssey',
				url: 'https://www.thenewhumanitarian.org/special-report/2022/05/10/us-asylum-darien-gap-cuba-central-america-mexico',
				socialTitle: 'A Cuban asylum seeker’s dangerous odyssey',
				socialDescription:
					"Record numbers are attempting the treacherous continent-crossing route through the Darién Gap to the United States. Here’s one person's story.",
				socialImage: 'https://assets.thenewhumanitarian.org/s3fs-public/styles/responsive_large/public/teaser.jpg.webp?itok=T7Y9fA9j',
				nodeId: 262413,
				updatedTime: '2022-05-10T10:00:00+01:00',
				modiefiedTime: '2022-05-10T10:00:00+01:00',
				publishedTime: '2022-05-10T10:00:00+01:00',
			}}
		>
			<div className={'bg-jungle'} id={'lat-am-migration'}>
				<div id='start'>
					<div
						className={'h-screen/3-5 sm:h-screen-desktop w-full sm:full-screen relative flex flex-col justify-center items-center cursor-pointer'}
						onClick={() => scrollToElement('story-start')}
						style={{
							overflow: 'hidden',
						}}
					>
						<div className={'px-8 sm:px-0 text-center inverted overflow-hidden w-full'}>
							<VideoHeader images={Images}>
								<h1 className={'z-50 rounded mx-auto inline-block mb-4 text-4xl sm:text-6xl md:text-5xl lg:text-6xl xl:text-7xl'}>
									<span className={'bg-jungle sm:py-1 bg-opacity-80 sm:leading-normal'}>‘The invisibles’: A Cuban asylum seeker’s dangerous odyssey</span>
								</h1>
								<p className={'z-50 text-2xl sm:text-4xl'}>
									<span className={'bg-jungle sm:leading-normal rounded bg-opacity-80'}>‘I wouldn’t recommend that journey to anyone.’</span>
								</p>
							</VideoHeader>
						</div>
					</div>
					<div id='story-start' className={'bg-jungle py-8 sm:py-24 relative flex flex-col justify-center items-center z-30 transition-opacity w-full'}>
						<AuthorInfo />
						<ArticleTags />
						<div className={'px-8 sm:px-0 inverted flow max-w-6xl field-name-body w-full text-left'}>
							<DateLine />
						</div>
						<div className={'px-8 sm:px-0 text-left inverted flow max-w-6xl field-name-body'}>
							<p>
								Migrants and asylum seekers are being apprehended in record numbers at the southern US border, where a new surge is expected from later this
								month as COVID-related restrictions are lifted. They come from Mexico and Central America, but also from the Caribbean and South America, even
								from Africa and the Middle East, escaping disasters, poverty, and repression. In the countries they transit through, they’re often viewed by
								authorities as numbers to register, problems to control, even to expel. For most, the goal is to reach the US to build new lives. In spite of
								the dangers and endless bureaucratic and legal hurdles, some do succeed. All experience suffering and fear along the way. This is the journey of
								one of them.
							</p>

							<p>
								Will* is an asylum seeker from Cuba, where rising poverty and discontent sparked rare mass street protests last year followed by an immediate
								government crackdown. Food, medicine, and fuel shortages – worsened by the pandemic, the war in Ukraine, and US sanctions – have seen{' '}
								<Link href='https://www.washingtonpost.com/national-security/2022/04/21/us-cuba-migration-border/'>record numbers</Link> of Cubans taken into
								custody at the US-Mexico border: Cubans are second now only to Mexicans in terms of monthly apprehensions, ahead of Guatemalans and Hondurans.
							</p>

							<p>
								After relocating to Venezuela in 2017 on the orders of Havana, Will took the decision to leave the country in 2021 after the Venezuelan
								government told him he risked being deported back to Cuba due to his opposition to the socialist Maduro regime that holds power in Caracas.
							</p>

							<p>
								To reach the United States, Will faced a journey of 6,000 kilometres overland from Venezuela. He would have to navigate some of the most
								dangerous migration routes in the world: the Darién Gap, a jungle corridor between Panama and Colombia controlled by armed groups and smugglers;
								Central America, where migrants are routinely preyed upon; and southern Mexico, where a whole new set of risks awaited.
							</p>

							<p>
								“Migrants face incredible dangers,” William Spindler, the Panama-based regional spokesperson for the UN’s refugee agency, UNHCR, told The New
								Humanitarian. “We see people robbed multiple times throughout the journey. People disappear. Women often face sexual assault. Some are detained
								by authorities in the countries they pass through.”
							</p>

							<p>
								Through face-to-face and phone interviews, as well as regular updates via WhatsApp, The New Humanitarian pieced together the route Will took and
								explored the challenges he faced – and which{' '}
								<Link href='https://www.migracion.gob.pa/images/img2021/pdf/IRREGULARES_POR_DARIEN_DICIEMBRE_2021.pdf'>
									tens of thousands of people like him
								</Link>{' '}
								continue to face every month.
							</p>

							<div className={'pt-2 pb-3'}>
								<InView
									className={`text-left inverted mt-15 sm:mt-16 flow w-full`}
									as='div'
									threshold={0.4}
									onChange={(inView) => {
										if (inView) {
											debouncedSetCounterStarted(true)
										} else {
											debouncedSetCounterStarted(false)
										}
									}}
								>
									<CounterComponent started={counterStarted} />
								</InView>
							</div>

							<p>
								It was in Acandí, a small but bustling jungle town near the southeastern entrance to the Darién Gap, that we first encountered
								40-something-year-old Will in late August 2021. Like other migrants, he had arrived there on a small boat from Necoclí, a town across the Gulf
								of Urabá that’s reachable by road from the rest of the country. In Acandí, hordes of vendors peddled overpriced goods for the trek ahead, while
								a folksy Colombian country music called <span className={'italic'}>vallenato</span> blared endlessly from the hut where “security guards”
								belonging to a local armed group oversaw the murky business of human smuggling.
							</p>

							<p>
								Sitting on a log near a tent he had brought for the trip, the silver-haired migrant – who asked that his last name be withheld – reviewed the
								survival equipment he had gathered for the arduous hike ahead. As he continued his preparations, hundreds more migrants mulled around nearby,
								many of them clearly worried about the next stage of their journey. Informal “guides” hawked their services, haggling with the small groups they
								were offering to lead for five to eight days through the dense jungle, which is controlled by{' '}
								<Link href='https://www.businessinsider.com/colombian-cartel-profiting-off-of-migrants-going-through-deadly-jungle-2021-10'>
									armed criminal groups
								</Link>{' '}
								on both sides of the Colombia-Panama border.
							</p>

							<p>
								At the time Will was in Acandí, the Colombian authorities said they had{' '}
								<Link href='https://www.thenewhumanitarian.org/video/2021/10/7/Central-American-migration-route-Darien-Gap-colombia-panama-dangerous'>
									never seen so many people passing
								</Link>{' '}
								through the hostile region. Numbers have soared even higher in the eight months since.
							</p>
						</div>
					</div>

					<Divider />

					<ChapterTitle text={'Surviving the Darién Gap'} />

					{/* Map grid with two cols */}
					<div className={`mt-24 sm:mt-0 relative w-full flex flex-col md:grid md:grid-cols-2 text-gray-300 shadow-none sm:shadow-none`} id={'map-start-1'}>
						<Sticky top={isMobile ? 8 : 80} innerZ={999} bottomBoundary={'#map-start-1'} activeClass={'sm:pt-32'}>
							<div
								className={`relative left-0 top-0 w-full h-0 pt-fourbythree sm:h-screen-desktop p-0 sm:p-0 shadow-2xl sm:shadow-none sm:bg-transparent z-50 ${chapter < 1 ? 'opacity-100' : ''
									}`}
							>
								<AnimatePresence>
									<InfographicOverlay chapter={chapter} key={`infographic-overlay-${chapter}`} images={props.data.allFile.edges} />
								</AnimatePresence>
							</div>
						</Sticky>

						{/* Map grid section 1 */}
						<div className={`flow inverted bg-transparent z-1 mt-20 sm:mt-0 md:-mt-14 transition-opacity`}>
							<MapGridWrapper>
								<MapGridSection chapter={1} sticky>
									<div className={'flex items-center justify-center w-full mb-12 sm:pt-64 flex-col'}>
										<span className={'relative w-16 h-16 inline-block'}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='100%'
												height='auto'
												viewBox='0 0 100 100'
												className={'fill-current w-full inline h-full absolute top-0 left-0'}
											>
												<path d='M50 2.5c-13.199 0-23.898 10.699-23.898 23.898v22.699c0 9.8 6 18.301 14.5 22v-7.7c-4.602-3.1-7.7-8.3-7.7-14.3l-.003-22.7C32.899 17 40.602 9.3 50 9.3c9.5 0 17.102 7.699 17.102 17.102V49.1c0 6-3.102 11.199-7.7 14.3v7.7c8.5-3.7 14.5-12.102 14.5-22L73.9 26.398C73.9 13.199 63.2 2.5 50.002 2.5z'></path>
												<path d='M50 32.699c1.898 0 3.398-1.5 3.398-3.398v-9.2c0-1.898-1.5-3.398-3.398-3.398s-3.398 1.5-3.398 3.398v9.2c0 1.8 1.5 3.398 3.398 3.398zM59.102 80.301l-5.7 5.7-.003-29.899c0-1.898-1.5-3.398-3.399-3.398s-3.398 1.5-3.398 3.398v29.801l-5.7-5.699c-1.3-1.3-3.5-1.3-4.8 0-1.3 1.3-1.3 3.5 0 4.8l11.5 11.5c.7.7 1.5 1 2.398 1s1.7-.3 2.399-1l11.5-11.5c1.3-1.3 1.3-3.5 0-4.8-1.399-1.305-3.5-1.305-4.797.098z'></path>
											</svg>
										</span>
										<h2 className={'text-2xl sm:text-4xl pt-8 text-center'} style={{ margin: 0 }}>
											Keep scrolling down to follow Will’s journey.
										</h2>
									</div>
								</MapGridSection>

								<MapGridSection chapter={1}>
									<RoundNumber number={1} />
									<p>
										Those seeking to cross the Darién Gap must first reach Necoclí, a migration hub on the eastern side of the Gulf of Urabá, before crossing
										via a small ferry to Acandí. An agreement between Colombia and Panama in August 2021 to cap the number of migrants crossing the gulf daily
										to 500 was quickly overwhelmed. Demand was much higher, with up to 20,000 migrants amassing in Necoclí by late September. Some try their
										luck with smugglers and attempt clandestine sea crossings, resulting at times in deadly shipwrecks.
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-2'} />

								<MapGridSection chapter={2}>
									<ChapterMarker id='chapter-2' />
									<RoundNumber number={2} />
									<p>
										On arrival in Acandí, migrants hire informal guides. After being matched up, the guides wake everyone the following day at 3:45am for a
										pre-dawn departure.
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-3'} />

								<MapGridSection chapter={3}>
									<ChapterMarker id='chapter-3' />
									<RoundNumber number={3} />
									<p>
										Within 10 minutes, migrants find themselves waist-deep in a river, their clothes and footwear already sodden. Walking upstream is slow, the
										uneven stones taking a toll on travellers' ankles. Many embark on the journey in flip flops, Crocs, or now-ruined Nikes. Often, they end up
										in shin-deep mud, pulling a foot out only to find the shoe gone. Some recover their buried shoes. Others don’t and have to continue in their
										socks or barefoot.
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-4'} />

								<MapGridSection chapter={4}>
									<ChapterMarker id='chapter-4' />
									<RoundNumber number={4} />
									<p>
										A few hours into Will’s journey, a woman faints. She is in no physical condition for this trip, but she has no choice. The alternative is to
										remain in Colombia without her family. Her fellow travellers help her up, but she is soon reduced to crawling on her hands and knees through
										the mud. They encourage her to continue, even though they have no real idea how much farther they have to go.
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-5'} />

								<MapGridSection chapter={5}>
									<ChapterMarker id='chapter-5' />
									<RoundNumber number={5} />
									<p>
										Between Acandí and Bajo Chiquito on the Panamanian side, migrants are at the mercy of armed and criminal groups. “I hear criminals are
										robbing people of everything they have,” says Yordany Herrera, a Cuban who travelled by land from Peru to the Darién Gap and is headed to
										the United States. “I’m not worried. I will stop to work along the way if I need to. I’ve been a chef, a construction worker, a waiter, a
										carpenter. I can do anything.”
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-6'} />

								<MapGridSection chapter={6} transparent>
									<ChapterMarker id='chapter-6' />
									<RoundNumber number={6} />
									<div className={'text-3xl sm:text-4xl md:text-4xl lg:text-4xl leading-snug sm:leading-loose sm:py-24 w-full flow'}>
										<p>
											<ResponsiveArrow /> Will at his tent, somewhere on the Colombian side of the Darién Gap, late August 2021.
										</p>
									</div>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-7'} />

								<MapGridSection chapter={7}>
									<ChapterMarker id='chapter-7' />
									<RoundNumber number={7} />
									<p>
										Two days after departing Acandí, migrants arrive at the Panamanian border. It’s the end of the road for the Colombian guides, as the
										Panamanian side is controlled by different armed groups. For many migrants, it’s confusing and disappointing to discover they are still less
										than half way through the Darién Gap: another two to five days of travel remain, depending on their pace.
									</p>
								</MapGridSection>

								<ScrollDownIcon scrollTo={'chapter-8'} />

								<MapGridSection chapter={8}>
									<ChapterMarker id='chapter-8' />
									<RoundNumber number={8} />
									<p>
										Finally, the migrants who have survived the journey arrive in the small Indigenous community of Bajo Chiquito, where new arrivals are
										registered before being transported along the Turquesa river to a migrant reception centre in the small town of Lajas Blancas.
									</p>
								</MapGridSection>
							</MapGridWrapper>

							<div className={'sm:mb-48'} />
						</div>
					</div>

					<div className={'relative mt-36 sm:mt-0'}>
						<InlineVideoSection fileName={'VOICE NOTE 1_1'} muted={false} controls />
					</div>

					<ChapterReset chapter={9} />

					<div className={'w-full relative flex flex-col justify-center items-center pt-20 pb-32'}>
						<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
							<p className={'hidden'} />
							<p>
								Six days after we said goodbye to him in Acandí, The New Humanitarian received a phone call from Will. He had made it through to the Panamanian
								side of the Darién Gap. “I wouldn’t recommend that journey to anyone,” he said. “It’s dangerous. There isn’t clean water. The terrain is brutal.
								We saw dead bodies. No matter how much you want to get to Mexico or the US, it isn’t worth the risk to cross that jungle.
							</p>
							<p>
								Asked months later how many corpses he had seen, Will responded: "I counted around 49. There could have been more. There were many bodies that
								you couldn't see, but when you passed you could smell the odour and know that there was a dead person there."
							</p>
							<p>
								Aid groups operating in the region said migrants often end up dead or missing in the Darién Gap, which can only be crossed on foot. On the
								Panamanian side, robberies and assaults by the local criminals who control the trail are commonplace.
							</p>
							<p>
								“We are treating victims of sexual assault, wounds resulting from attacks, pregnancy complications, families arriving incomplete,” said Santiago
								Valenzuela of Médecins Sans Frontières (MSF). The organisation operates an{' '}
								<Link href='https://www.doctorswithoutborders.org/what-we-do/news-stories/story/panama-surviving-dangers-darien-gap'>outpost</Link> in Bajo
								Chiquito, a small Indigenous community as you emerge from the jungle on the Panamanian side.
							</p>
							<p>
								Last year alone, MSF provided medical treatment to over 40,000 migrants at Bajo Chiquito, and further downriver in the small town of Lajas
								Blancas.
							</p>
							<p>
								“We treated one woman who was trapped in the jungle for three months due to a broken ankle. She was suffering from malnutrition and acute
								dehydration as well as secondary infections,” Valenzuela recalled. “Some people have been robbed three or four times. They emerge with nothing.”
							</p>
							<p>
								In 2021, Panamanian officials{' '}
								<Link href='https://apnews.com/article/caribbean-panama-united-states-358e8ef8d1696c56ecf153c7fc9d709f'>recovered 50 bodies</Link> from the
								Darién Gap, more than double the 2020 figure. They believe that’s only a small portion of those who succumbed during the trek, as most deaths go
								unreported and their remains are never retrieved.
							</p>
							<p>
								The Panamanian government said more than{' '}
								<Link href='https://www.reuters.com/world/americas/big-spike-migrant-numbers-crossing-darien-gap-2021-figures-unhcr-2022-03-29/#:~:text=According%20to%20statistics%20from%20Panamanian,2021%2C%20which%20saw%202%2C819%20Venezuelans'>
									130,000 migrants
								</Link>{' '}
								entered the country from Colombia in 2021. Nearly 80,000 were Haitians, but many also came from Cuba and Venezuela, and from a range of West
								African countries.
							</p>
							<p>
								UNHCR, MSF, the International Federation of Red Cross and Red Crescent Societies (IFRC), and the International Organization for Migration (IOM)
								all work with the Panamanian government to <Link href='https://news.un.org/en/story/2022/04/1115892'>provide medical care</Link> and temporary
								shelter to thousands waiting in small communities near the Colombian border.
							</p>
							<p>
								There’s limited capacity at the migrant reception centre in Bajo Chiquito. On arrival in Panama, most migrants are placed in a processing camp
								in Lajas Blancas, where they often have to wait for weeks to continue north. Since the pandemic, migrants have also had to spend two weeks in
								quarantine to prevent the spread of COVID-19.
							</p>
							<p>
								Will said he arrived in Panama “destroyed, wasted, and bloodied”, before spending two weeks in the government-run centre in Bajo Chiquito. He
								was then allowed to continue northwards by boat, before being{' '}
								<Link href='https://www.americaeconomia.com/politica-sociedad/panama-registra-un-record-de-migrantes-que-atraviesan-su-territorio-en-lo-que-va-de-2021'>
									transported discretely and efficiently
								</Link>{' '}
								to Panama’s border with Costa Rica. “They put you on a bus,” he said. “They don't want you to have any contact with Panama. They pick you up in
								Lajas Blancas and drop you off at the border. The whole trip is at night so that there’s absolutely no contact with people."
							</p>
						</div>
					</div>
				</div>

				<Divider margin={[0, 24]} />

				<ChapterTitle text={'Staying hidden through Central America'} />

				<div className={'mt-8 sm:mt-0 w-full relative flex flex-col justify-center items-center sm:pt-20 pb-32'}>
					<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
						<p className={'hidden'} />
						<p>
							From Panama, migrants and asylum seekers disperse into smaller groups as they head through Central America and into Mexico, often crossing borders
							irregularly and on smugglers’ trails to avoid detection, detention, and deportation by the authorities.
						</p>
						<p>
							Existing outside of any government databases – the Panamanian government registers those it finds arriving from the Darién Gap – they become less
							visible to officials, as well as to any aid groups collecting data.
						</p>
						<p>
							“The migration is irregular and very difficult to track,” said Spindler, the UNHCR spokesperson. “They cross most of the continent as invisibles.
							We only see them at concentration points where they are forced to wait, like Necoclí or southern Mexico.
						</p>
						<p>
							“We’re talking about the main migration route in the Americas,” he continued. “It is one of the most dangerous migration routes in the world, but
							we only hear about it at bottlenecks or when tragedies happen.”
						</p>
						<p>
							The exceptions are the occasional{' '}
							<Link href='https://www.amnesty.org/en/latest/news/2018/11/key-facts-about-the-migrant-and-refugee-caravans-making-their-way-to-the-usa/'>
								caravans
							</Link>{' '}
							– large groups – of Hondurans and Guatemalans who congregate in their thousands and travel together for safety, only to often be turned away at
							borders and/or <Link href='https://newsrnd.com/life/2021-09-05-mexico--police-scatter-caravan-of-400-migrants.SkS8D5zfK.html'>broken up</Link> as{' '}
							<Link href='https://www.aljazeera.com/news/2021/11/4/migrants-injured-in-fresh-clashes-with-mexicos-national-guard'>crackdowns ensue</Link>.
						</p>
						<p>
							Although most Central American countries don’t deport migrants, bureaucratic obstacles are increasing, including in{' '}
							<Link href='https://twitter.com/adam_wola/status/1503785862643032068?s=20&t=pj0TE1SGjzV280RGmIys7Q'>Mexico</Link>, where visa requirements for
							certain nationalities have been toughened and deportations are also on the rise. Nicaragua, which earlier in the pandemic was making its own
							citizens pay for <Link href='https://www.youtube.com/watch?v=z9rZ53ux-5o'>expensive COVID-19 tests</Link> to return to the country – has begun
							charging migrants $150, the same magic number – to transit through.{' '}
						</p>
						<p>
							Will went more silent as he made his way through Central America, checking in less frequently. He said he felt lucky to have made it through the
							Darién Gap without serious incident. But on crossing the border into Costa Rica, he was robbed for his cellphone and the cash he carried. He then
							had to work informally for several weeks to raise enough money to cross Nicaragua.
						</p>
					</div>
				</div>

				{/* Map grid with two cols */}
				<div className={`mt-0 relative w-full flex flex-col md:grid md:grid-cols-2 text-gray-300 shadow-none sm:shadow-none`} id={'map-start-2'}>
					{/* Left side element */}
					<Sticky top={isMobile ? 8 : 80} innerZ={999} bottomBoundary={'#map-start-2'} activeClass={'sm:pt-32'}>
						<div
							className={`relative left-0 top-0 w-full h-0 pt-fourbythree sm:h-screen-desktop p-0 sm:p-0 shadow-2xl sm:shadow-none sm:bg-transparent z-50 ${chapter < 1 ? 'opacity-100' : ''
								}`}
						>
							<AnimatePresence>
								<InfographicOverlay chapter={chapter} key={`infographic-overlay-${chapter}`} images={props.data.allFile.edges} />
							</AnimatePresence>
						</div>
					</Sticky>

					{/* Map grid section 1 */}
					<div className={`flow inverted bg-transparent z-1 mt-20 sm:mt-0 md:-mt-14 transition-opacity`}>
						<MapGridWrapper>
							<MapGridSection chapter={9} sticky>
								<div className={'flex items-center justify-center w-full mb-12 sm:pt-64 flex-col'}>
									<span className={'relative w-16 h-16 inline-block'}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='100%'
											height='auto'
											viewBox='0 0 100 100'
											className={'fill-current w-full inline h-full absolute top-0 left-0'}
										>
											<path d='M50 2.5c-13.199 0-23.898 10.699-23.898 23.898v22.699c0 9.8 6 18.301 14.5 22v-7.7c-4.602-3.1-7.7-8.3-7.7-14.3l-.003-22.7C32.899 17 40.602 9.3 50 9.3c9.5 0 17.102 7.699 17.102 17.102V49.1c0 6-3.102 11.199-7.7 14.3v7.7c8.5-3.7 14.5-12.102 14.5-22L73.9 26.398C73.9 13.199 63.2 2.5 50.002 2.5z'></path>
											<path d='M50 32.699c1.898 0 3.398-1.5 3.398-3.398v-9.2c0-1.898-1.5-3.398-3.398-3.398s-3.398 1.5-3.398 3.398v9.2c0 1.8 1.5 3.398 3.398 3.398zM59.102 80.301l-5.7 5.7-.003-29.899c0-1.898-1.5-3.398-3.399-3.398s-3.398 1.5-3.398 3.398v29.801l-5.7-5.699c-1.3-1.3-3.5-1.3-4.8 0-1.3 1.3-1.3 3.5 0 4.8l11.5 11.5c.7.7 1.5 1 2.398 1s1.7-.3 2.399-1l11.5-11.5c1.3-1.3 1.3-3.5 0-4.8-1.399-1.305-3.5-1.305-4.797.098z'></path>
										</svg>
									</span>
									<h2 className={'text-2xl sm:text-4xl pt-8 text-center'} style={{ margin: 0 }}>
										Keep scrolling down to follow Will’s journey.
									</h2>
									<p className={'text-center mt-48'}>
										From Bajo Chiquito, migrants begin long journeys through Central America beset by obstacles ranging from extortion to unexpected
										border-crossing fees.
									</p>
								</div>
							</MapGridSection>

							<MapGridSection chapter={9} transparent>
								<ChapterMarker id='chapter-9' />
								<RoundNumber number={1} />
								<div>
									<GatsbyImage image={getImageByName('mexico-1a')} objectFit={'cover'} className={'mt-8'} alt='No alt text found.' />
									<p className={'pt-6 text-center'}>
										Text message from Rudy, Will's travel companion, to The New Humanitarian (original message on the left, English translation on the right)
									</p>
								</div>
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-10'} />

							<MapGridSection chapter={10} transparent>
								<ChapterMarker id='chapter-10' />
								<RoundNumber number={2} />
								<ChapterVideo fileName={'VOICE NOTE 2_10'} />
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-11'} />

							<MapGridSection chapter={11} transparent>
								<ChapterMarker id='chapter-11' />
								<RoundNumber number={3} />
								<GatsbyImage image={getImageByName('mexico-3a')} objectFit={'cover'} className={'mt-8'} alt='No alt text found.' />
								<p className={'pt-6 text-center w-full'}>
									Text message from Will, sent on 6 October 2021 from Costa Rica’s border with Nicaragua (the photo opposite is an archive photo of the same
									border crossing taken in 2015).
								</p>
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-12'} />

							<MapGridSection chapter={12} transparent>
								<ChapterMarker id='chapter-12' />
								<RoundNumber number={4} />
								<p>
									<ResponsiveArrow /> Migrants crossing Honduras, where thousands more people join the journey northwards, fleeing violence, poverty, climate
									disasters, and in search of a better life.
								</p>
							</MapGridSection>
						</MapGridWrapper>

						{/* Map grid section 2 */}

						<ScrollDownIcon scrollTo={'chapter-13'} />

						<MapGridWrapper>
							<MapGridSection chapter={13} lastChapter transparent>
								<ChapterMarker id='chapter-13' />
								<RoundNumber number={5} />
								<ChapterVideo fileName={'VOICE NOTE 3_13'} />
								<p className={'pt-6 text-center'}>
									At Guatemala’s frontier with Mexico, Will finds relatively easy passage. There are no guards patrolling the river border, which he crosses for
									a small fee.
								</p>
							</MapGridSection>
						</MapGridWrapper>
						<div className={'sm:mb-56'} />
					</div>
				</div>

				{/* Back to paragraph layout */}
				<div className={'w-full relative flex flex-col justify-center items-center'}>
					<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
						<p className={'hidden'} />
						<p>Women are particularly vulnerable during this part of the journey.</p>
						<p>
							In a 2021 <Link href='https://gwbcenter.imgix.net/Publications/Reports/gwbi_Immigration,_Security,_and_Gender-Based_Violence.pdf'>report</Link> by
							the Wilson Center: “Of the 25 most dangerous places in the world for women, 10 are in the Western Hemisphere, with Central American countries
							Honduras and El Salvador near the top of the list, at numbers two and four respectively.” Migrant women are even more vulnerable than citizens of
							the region.{' '}
						</p>
						<p>
							According to <Link href='https://www.msf.org/mexico-unsafe-country-thousands-refugees-fleeing-violence-central-america'>MSF</Link>, 68 percent of
							migrants and asylum seekers interviewed along the Central American migration route are exposed to violence, and nearly one third of women surveyed
							had been sexually abused. It has described the high levels of violence in the region as comparable to those in the war zones it operates in.
						</p>
						<p>
							At this stage of the route, joining those like Will who arrived through the Darién Gap from South America, or who have flown directly into the
							region from the Caribbean or elsewhere, are thousands of migrants from Central America’s Northern Triangle countries: Honduras, El Salvador, and
							Guatemala. Record numbers of Central Americans, often escaping crime or natural disasters as well as poverty, headed northward in 2021.
						</p>
					</div>
				</div>

				<div className={'mt-28'} />

				<Divider margin={[0, 24]} />

				<div className={'text-center sm:pb-24'}>
					<ChapterTitle text={'To stay in Mexico or go on?'} />
				</div>

				<div className={'w-full relative flex flex-col justify-center items-center'}>
					<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
						<p className={'hidden'} />

						<div className={'max-w-6xl mx-auto py-6'}>
							<div className={'overflow-visible'}>
								<div className={'sm:mx-auto md:-mx-24 lg:-mx-56'}>
									<GatsbyImage image={getImageByName('albergue-jesus-el-buen-pastor')} objectFit={'cover'} className={'w-full'} alt='No alt text found.' />
								</div>
								<p className={'text-center text-gray-400 pb-2 pt-6 px-4 text-2xl sm:-mx-12'}>
									Migrants queuing in May 2021 for dinner at the Jesús El Buen Pastor shelter in Tapachula, Mexico, where they are forced to wait for months
									before being allowed to proceed northwards. (Jordan Stern/TNH)
								</p>
							</div>
						</div>

						<p>
							When migrants and asylum seekers reach Mexico, they have to announce themselves to authorities or risk being detained or deported. The migrant
							population once again becomes visible in large numbers, especially in small border towns in some of the country's poorest states.
						</p>

						<p>
							Arrival here may mean waiting for months for documentation – both for refugee status within Mexico and{' '}
							<Link href='https://thehill.com/latino/595457-migrants-police-clash-in-southern-mexico-as-frustrations-rise-over-passage-to-us/'>permission</Link>{' '}
							to head to the US border – before being allowed to continue northward. In the interim, employment opportunities are virtually nil.
						</p>
						<p>
							Tapachula, where many migrants and asylum seekers find themselves legally stranded, is blisteringly hot. During the day, thousands wait endlessly
							in the shade, clutching identical translucent plastic envelopes stuffed with application papers or wearing their passports around their necks.
							Most possessions can be replaced, but the immigration papers contained in those envelopes are priceless and never leave their side.
						</p>
						<p>
							None of the new arrivals want to be in Tapachula, but the town has become the de facto holding ground for migrants from Central America and
							beyond. Those attempting to leave the region while their cases are being reviewed may end up at{' '}
							<Link href='https://www.pbs.org/newshour/world/overcrowding-and-abuse-witnessed-at-mexico-migrant-detention-center'>Siglo XXI</Link>, Latin
							America’s biggest migrant detention centre, notorious for overcrowding and allegations of detainee abuse.
						</p>
						<p>
							In 2021, Mexico detained over a{' '}
							<Link href='https://thehill.com/latino/587522-mexico-detained-more-than-250000-migrants-in-2021'>quarter of a million</Link> migrants, the
							majority in Tapachula. According to the law, migration cases for detained foreigners should be resolved in 15 working days, but that provision was
							extended to 90 due to the backlog, and some migrants report waiting even longer.
						</p>
						<p>
							As a result, many give up plans to continue north and decide to apply for asylum in Mexico. Asylum claims in Mexico reached an{' '}
							<Link href='https://abcnews.go.com/International/wireStory/mexico-refugee-asylum-claims-double-2021-82058574'>all-time high in 2021</Link> of over
							130,000. Detentions and deportations of migrants also{' '}
							<Link href='https://www.gob.mx/cms/uploads/attachment/file/684996/Cierre_Noviembre-2021__1-Diciembre-2021_.pdf'>skyrocketed</Link>, according to
							government statistics.
						</p>
						<p>
							Mexico’s southern border states of Chiapas and Tabasco have been unable to cope with the soaring number of asylum applicants, most of whom stay in
							the border cities, explained Adam Isacson, of the Washington Office on Latin America (WOLA). “COMAR (the Mexican migration service) doesn’t have
							the resources or the personnel to handle this,” Isacson said. “They are overwhelmed.”{' '}
						</p>
						<p>
							UNHCR has{' '}
							<Link href='https://www.unhcr.org/news/stories/2022/1/61c254184/refugee-family-found-safety-stability-mexico-next-step-citizenship.html'>
								programmes
							</Link>{' '}
							designed to help those who decide to stay in Mexico regularise their migration status, find work, and obtain housing, but it’s a process that
							takes time, and the agency’s workers in southern Mexico are unable to assist everyone.
						</p>
						<p>
							Spindler said the organisation had requested more resources from both the US and the UN to deal with the{' '}
							<Link href='https://www.unhcr.org/news/briefing/2021/6/60bf24204/mexico-relocations-refugee-integration-top-10000-mark.html'>
								increased demand
							</Link>{' '}
							in the region – not only to assist the increased numbers of migrants heading northwards, but also deportees sent back by the US.{' '}
						</p>
						<p>
							After being robbed in Costa Rica and narrowly escaping detention in Nicaragua – he only avoided it by bribing a border guard – Will eventually
							managed to cross into Mexico from Guatemala, where he made contact once again.
						</p>
						<p>
							Although Will did manage to avoid Siglo XXI – a slice of good fortune other migrants in touch with The New Humanitarian did not share – he was
							still left languishing for weeks, awaiting Mexico's labyrinthine bureaucracy, like virtually all his fellow travellers.{' '}
						</p>
						<p>
							“I am opting to apply for the humanitarian visa, but they told me that I cannot leave the state with the humanitarian visa,” Will messaged from
							Palenque, in northern Chiapas. “It doesn’t matter. If they give it to me, I will take off and go from here to there.”
						</p>
						<p>While cases are reviewed, migrants are prohibited from leaving the department in which they filed their claim.</p>
						<p>
							<Link href='https://www.kcra.com/article/migrants-march-from-mexico-as-us-lifts-covid-ban/39612202#'>Ongoing protests</Link> by migrants in
							southern Mexico demanding the right to work and continue north have further strained difficult relations with local residents, leading to a rise
							in anti-immigrant sentiment.
						</p>
						<p>Locals often complain about increasing crime rates, being “overrun” by migrants, and how Tapachula has changed for the worse.</p>
						<p>Graver risks from drug and criminal gangs are also ever-present.</p>
						<p>“There is no work here,” Will said from Palenque. “Just gangs, organised crime, hunger, and xenophobia.”</p>
						<p>
							One of Will’s travel companions was kidnapped shortly after his arrival in Mexico. For three days, he heard nothing and assumed he had been
							killed. Will then learned that the kidnappers had in fact released his companion after securing money from the man’s relative in the United
							States.
						</p>
					</div>
				</div>

				<div className={'mt-28'} />

				<ChapterReset chapter={14} />

				<Divider margin={[0, 24]} />

				<div className={'text-center sm:pb-24'}>
					<ChapterTitle text={'To the US border'} />
				</div>

				<div className={'mb-24 sm:mb-0 w-full relative flex flex-col justify-center items-center'}>
					<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
						<p className={'hidden'} />
						<p>
							After making it through the Darién Gap and overcoming the legal and bureaucratic hurdles of Central America and southern Mexico, you might think
							the worst part of the journey to the United States is over. But Will’s updates underlined that this is far from being the case: Mexico is also an
							extremely dangerous place to be a migrant.
						</p>
						<p>
							Fear among those about to embark on the journey from southern Mexico is palpable. In Tapachula, no one wants to give their last name, talk about
							where they are going, where they are from, or if they have family or friends in the United States.
						</p>
						<p>
							They fear kidnapping, extortion, and robbery from criminals, as well as detention and deportation from the state. And the well-travelled migrant
							corridor to the United States is also lucrative territory for Mexico’s notorious drug cartels, who{' '}
							<Link href='https://www.nbcnews.com/news/latino/are-cartels-capture-extort-torture-migrants-8-mexican-states-rcna2454'>
								battle between each other for its control
							</Link>
							. Those on the move are also distrustful of Mexican police, who have been involved in multiple{' '}
							<Link href='https://www.bbc.com/news/world-latin-america-55918216'>killings of migrants</Link>; as have the Mexican National Guard, most recently
							in November, when they opened fire on a bus transporting migrants, killing one and wounding 19.
						</p>
						<p>
							An{' '}
							<Link href='https://www.france24.com/en/americas/20211210-dozens-killed-in-southern-mexico-as-trailer-carrying-migrants-crashes'>accident</Link>{' '}
							in October in Chiapas of a speeding truck illegally transporting migrants that left at least 54 dead drew attention to another risk. The Mexican
							government has repeatedly vowed to crack down on smuggling groups and cartels who profit from the routes to the US, but little to no progress
							appears to have been made.
						</p>
					</div>
				</div>

				{/* Map grid with two cols */}
				<div className={`mt-0 relative w-full flex flex-col md:grid md:grid-cols-2 text-gray-300 shadow-none sm:shadow-none`} id={'map-start-3'}>
					{/* Left side element */}
					<Sticky top={isMobile ? 8 : 80} innerZ={999} bottomBoundary={'#map-start-3'} activeClass={'sm:pt-32'}>
						<div
							className={`relative left-0 top-0 w-full h-0 pt-fourbythree sm:h-screen-desktop p-0 sm:p-0 shadow-2xl sm:shadow-none sm:bg-transparent z-50 ${chapter < 1 ? 'opacity-100' : ''
								}`}
						>
							<AnimatePresence>
								<InfographicOverlay chapter={chapter} key={`infographic-overlay-${chapter}`} images={props.data.allFile.edges} />
							</AnimatePresence>
						</div>
					</Sticky>

					{/* Map grid section 1 */}
					<div className={`flow inverted bg-transparent z-1 mt-20 sm:mt-0 md:-mt-14 transition-opacity`}>
						<MapGridWrapper>
							<MapGridSection chapter={14} sticky>
								<div className={'flex items-center justify-center w-full mb-12 sm:pt-64 flex-col'}>
									<span className={'relative w-16 h-16 inline-block'}>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='100%'
											height='auto'
											viewBox='0 0 100 100'
											className={'fill-current w-full inline h-full absolute top-0 left-0'}
										>
											<path d='M50 2.5c-13.199 0-23.898 10.699-23.898 23.898v22.699c0 9.8 6 18.301 14.5 22v-7.7c-4.602-3.1-7.7-8.3-7.7-14.3l-.003-22.7C32.899 17 40.602 9.3 50 9.3c9.5 0 17.102 7.699 17.102 17.102V49.1c0 6-3.102 11.199-7.7 14.3v7.7c8.5-3.7 14.5-12.102 14.5-22L73.9 26.398C73.9 13.199 63.2 2.5 50.002 2.5z'></path>
											<path d='M50 32.699c1.898 0 3.398-1.5 3.398-3.398v-9.2c0-1.898-1.5-3.398-3.398-3.398s-3.398 1.5-3.398 3.398v9.2c0 1.8 1.5 3.398 3.398 3.398zM59.102 80.301l-5.7 5.7-.003-29.899c0-1.898-1.5-3.398-3.399-3.398s-3.398 1.5-3.398 3.398v29.801l-5.7-5.699c-1.3-1.3-3.5-1.3-4.8 0-1.3 1.3-1.3 3.5 0 4.8l11.5 11.5c.7.7 1.5 1 2.398 1s1.7-.3 2.399-1l11.5-11.5c1.3-1.3 1.3-3.5 0-4.8-1.399-1.305-3.5-1.305-4.797.098z'></path>
										</svg>
									</span>
									<h2 className={'text-2xl sm:text-4xl pt-8 text-center'} style={{ margin: 0 }}>
										Keep scrolling down to follow Will’s journey.
									</h2>
								</div>
							</MapGridSection>

							<MapGridSection chapter={14} transparent>
								<ChapterMarker id='chapter-14' />
								<RoundNumber number={1} />
								<p>
									<ResponsiveArrow /> Mexican migration officials distribute information to migrants before they leave Tapachula. Here, the guidance they are
									being given is warning them about the dangers of crossing railway tracks or fast-flowing rivers when they head north. (Jordan Stern/TNH)
								</p>
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-15'} />

							<MapGridSection chapter={15} transparent>
								<ChapterMarker id='chapter-15' />
								<RoundNumber number={2} />
								<GatsbyImage image={getImageByName('usborder-2a')} objectFit={'cover'} className={'mt-8'} alt='No alt text found.' />
								<p className={'pt-6 text-center w-full'}>Text message from Will, sent on 8 December 2021 from Palenque, Mexico</p>
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-16'} />

							<MapGridSection chapter={16} transparent>
								<ChapterMarker id='chapter-16' />
								<RoundNumber number={3} />
								<GatsbyImage image={getImageByName('usborder-3a')} objectFit={'cover'} className={'mt-8'} alt='No alt text found.' />
								<p className={'pt-6 text-center w-full'}>Text message from Will, sent on 11 December from the road between Palenque and Villahermosa</p>
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-17'} />

							<MapGridSection chapter={17} transparent>
								<ChapterMarker id='chapter-17' />
								<RoundNumber number={4} />
								<p className={'text-center pb-6'}>
									Will sends us three video clips as he struggles to find safe passage through Mexico due to xenophobia and the extreme level of danger he faces
									from gangs and criminal elements, describing migrants as being “in the eye of the hurricane”.
								</p>
								<ChapterVideo fileName={'VOICE NOTE 4_17'} />
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-18'} />

							<MapGridSection chapter={18} transparent>
								<ChapterMarker id='chapter-18' />
								<RoundNumber number={5} />
								<ChapterVideo fileName={'VOICE NOTE 5_18'} />
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-19'} />

							<MapGridSection chapter={19} transparent>
								<ChapterMarker id='chapter-19' />
								<RoundNumber number={6} />
								<ChapterVideo fileName={'VOICE NOTE 6_19'} />
							</MapGridSection>

							<ScrollDownIcon scrollTo={'chapter-20'} />

							<MapGridSection chapter={20} lastChapter transparent>
								<ChapterMarker id='chapter-20' />
								<RoundNumber number={7} />
								<p className={'text-center pb-6'}>
									Finally, four months after we first encountered him in Acandí, we receive this clip from Will “safe and sound” in the United States.
								</p>
								<ChapterVideo fileName={'VOICE NOTE 7_20'} />
							</MapGridSection>
						</MapGridWrapper>
					</div>
				</div>

				<div className={'mt-28'} />

				{/* Back to paragraph layout */}
				<div className={'w-full relative flex flex-col justify-center items-center pb-32'}>
					<div className={'px-8 sm:px-0 mt-8 sm:mt-0 text-left inverted flow max-w-6xl field-name-body'}>
						<p className={'hidden'} />
						<p>
							President Andrés Manuel Obrador López has{' '}
							<Link href='https://www.nbcnews.com/news/latino/mexico-president-detaining-containing-migrants-not-enough-rcna1880?cid=sm_npd_nn_fb_lt&fbclid=IwAR2EpI20DJVq7jFU4FpME4VdBtYqFE80r-XjVJb4Wz3ERDy56iqd018I6_g'>
								acknowledged
							</Link>{' '}
							that Mexico’s strategy of simply trying to contain migrants in southern Mexico is “not enough”, and has called on the United States to increase{' '}
							<Link href='https://www.reuters.com/world/americas/us-gives-central-america-mexico-another-20mln-humanitarian-aid-2021-10-15/'>
								its assistance
							</Link>
							. In recent months, the Mexican government has been granting temporary humanitarian work visas to{' '}
							<Link href='https://www.kxxv.com/news/national/remain-in-mexico-reboot-to-grant-migrants-one-year-humanitarian-visas'>
								some of those stranded
							</Link>{' '}
							in Tapachula, allowing more of them to{' '}
							<Link href='https://www.reuters.com/world/us/mexico-moves-migrants-southern-state-asylum-seekers-awaited-us-border-2021-12-07/'>head north</Link>.
						</p>
						<p>
							But even after migrants do manage to make their way to northern Mexico, many don’t fare much better. Policies introduced under Donald Trump and{' '}
							<Link href='https://www.thenewhumanitarian.org/analysis/2022/1/24/one-year-Biden-refugee-asylum-promises'>
								continued by President Joe Biden’s administration
							</Link>
							, <Link href='https://www.dhs.gov/news/2019/01/24/migrant-protection-protocols'>including the Migrant Protection Protocols</Link> (MPP) and the
							pandemic-related <Link href='https://www.cbp.gov/newsroom/stats/cbp-enforcement-statistics/title-8-and-title-42-statistics'>Title 42</Link>, have
							forced them to{' '}
							<Link href='https://www.thenewhumanitarian.org/news-feature/2021/4/14/in-mexico-aid-efforts-struggle-to-keep-pace-with-soaring-migration-needs'>
								wait in overwhelmed towns
							</Link>{' '}
							and facilities where they are vulnerable again to extortion and violence from the cartels and criminal gangs. A recent announcement about the{' '}
							<Link href='https://www.cdc.gov/media/releases/2022/s0401-title-42.html'>lifting of Title 42</Link>, from 23 May, is expected to draw even more
							migrants to the border, and it is uncertain how the Mexican authorities will cope with the new influx.
						</p>
						<p>
							Due to the backlog in the US asylum system, the stringent conditions to qualify, and the lack of alternatives for economic migrants, many resort
							to trying to cross the US border irregularly, risking{' '}
							<Link href='https://www.thenewhumanitarian.org/news-feature/2018/11/06/migrants-US-Mexico-caravan-elections-Trump-water-desert'>
								death in the desert
							</Link>
							, or apprehension and deportation.
						</p>
						<p>
							“Without legal avenues for migration, migration will happen illegally, which means people’s lives are at risk as they resort to criminal
							structures,” explained Spindler, the UNHCR spokesperson.
						</p>
						<p>
							Since 2021, however, one nationality{' '}
							<Link href='https://www.reuters.com/world/us/us-warns-cubans-away-sea-crossings-amid-protests-most-cross-land-2021-07-13/'>
								largely spared from expulsion
							</Link>{' '}
							from the US has been Cubans. Unlike Haitians and Central Americans, many of whom are returned to their home countries, single Cuban adults are
							able to stay while they apply for asylum.
						</p>
						<p>
							Luckily for Will, this allowed him to complete his journey more easily than compatriots who attempted the trip prior to 2021. “I am now here in
							the United States. I arrived safe and sound,” Will finally messaged The New Humanitarian from Houston, Texas, four months after we first met him
							in Acandí.
						</p>
						<p>
							After crossing the border informally into the United States in late December, Will handed himself in to migration authorities. Still awaiting the
							completion of his asylum process, he offered few details about the final leg of his journey – he explained that he was working and too busy to
							answer our questions.
						</p>
						<p>
							Out of four migrants who remained in sporadic contact with The New Humanitarian for their journeys northward from Acandí – the other three were
							Haitians – Will was the only one who confirmed US entry without being deported or detained.
						</p>
						<p>
							Some broke off contact due to lost phones, legal issues, or simple distraction, some were detained by migration authorities. Others perhaps
							encountered more serious problems that went uncommunicated. Like millions before them, they are the latest “invisibles”.
						</p>

						<div className={'pt-6'} />

						<hr />

						<p className={'italic'}>*Will repeatedly declined to answer when pressed to confirm if Will was his real first name.</p>

						<p className={'italic'}>
							Additional reporting provided by Jordan Stern from Acandí, Colombia; the Darién Gap; and Tapachula, Mexico. Production by Whitney Patterson, Marc
							Fehr, and Ciara Lee. Edited by Paula Dupraz-Dobias.{' '}
						</p>
					</div>
				</div>
			</div>

			<div className={'bg-jungle p-8 flex items-center justify-center cursor-pointer'} onClick={() => scrollToElement('story-start')}>
				<p className={'text-gray-500'}>↑ Scroll to top</p>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query LatinAmericaMigrationImgs {
		allFile(
			filter: {
				sourceInstanceName: { eq: "images" }
				extension: { in: ["jpg", "png", "jpeg"] }
				relativePath: { glob: "stories/2022/latin-america-migration/*" }
			}
		) {
			edges {
				node {
					extension
					dir
					modifiedTime
					name
					relativePath
					childImageSharp {
						gatsbyImageData(width: 1280, quality: 85, placeholder: BLURRED, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`

export default LatinAmericaMigrantsStory
