import React, { useRef, useState, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

const InlineVideoSection = (props) => {
	const vidRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)

	useEffect(() => {
		if (isPlaying) {
			vidRef.current.play()
		} else {
			vidRef.current.pause()
		}
	}, [isPlaying])

	const playVideo = () => {
		vidRef.current.play()
		setIsPlaying(true)
	}

	const PlayIcon = () => {
		return (
			<svg xmlns='http://www.w3.org/2000/svg' width='100%' fill='none' viewBox='0 0 394 394' className={'fill-current'}>
				<path fill={'#282828'} d='M80.385 91.424H312.984V294.06100000000004H80.385z'></path>
				<path d='M197.375.75C88.925.75.75 88.925.75 197.375S88.925 394 197.375 394 394 305.825 394 197.375 305.825.75 197.375.75zm63.338 206.551l-92.732 61.687c-7.859 5.385-18.628-.42-18.628-9.928V135.699c0-9.524 10.769-15.312 18.628-9.928l92.716 61.67c7.049 4.544 7.049 15.313.016 19.857v.003z'></path>
			</svg>
		)
	}

	return (
		<InView
			as='div'
			// triggerOnce={true}
			// delay={isMobile ? 100 : null}
			// trackVisibility={isMobile}
			// threshold={1}
			// rootMargin={isMobile ? '0px' : '100px'}
			onChange={(inView) => {
				if (inView) {
					// console.log('IN VIEW')
					// vidRef.current.play()
				} else {
					// console.log('NOT IN VIEW')
					vidRef.current.pause()
				}
			}}
		>
			{!isPlaying && (
				<div className={'hidden sm:flex justify-center items-center w-full h-full absolute top-0 left-0 cursor-pointer'} onClick={() => playVideo()}>
					<motion.span
						className={'text-white z-50 w-20 h-20 sm:w-48 sm:h-48'}
						initial={{
							opacity: 0.5,
						}}
						animate={{
							opacity: 1,
							scale: [1, 1.2, 1.2, 1, 1],
							rotate: [0, 360],
							// borderRadius: ['20%', '20%', '50%', '50%', '20%'],
						}}
						whileHover={{ scale: 1.1 }}
						whileTap={{ scale: 0.9 }}
						transition={{ type: 'spring', stiffness: 100 }}
					>
						<PlayIcon />
					</motion.span>
				</div>
			)}
			<div className={'max-w-6xl mx-auto overflow-visible'}>
				<div className={'sm:mx-auto md:-mx-24 lg:-mx-56 shadow-2xl'}>
					<video
						className='w-full opacity-100'
						autoPlay={props.autoPlay}
						muted={props.muted}
						controls={props.controls || true}
						loop={props.loop}
						poster={require(`@images/stories/2022/latin-america-migration/${props.fileName}.jpg`).default}
						ref={vidRef}
						style={{
							objectFit: 'cover !important',
						}}
					>
						<source src={require(`@images/stories/2022/latin-america-migration/${props.fileName || 'Darien'}.mp4`).default} type={'video/mp4'} />
					</video>
				</div>
				{props.caption && <p className={'text-center text-gray-400 pb-2 pt-6 px-4 text-2xl'}>{props.caption}</p>}
			</div>
		</InView>
	)
}

export default InlineVideoSection
